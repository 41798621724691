.react-daterange-picker {
    width: 100%;
}

.react-daterange-picker__wrapper {
    justify-content: space-around;
    width: 257px;
    height: 40px;
    font-size: 14px;
    line-height: 140%;
    text-align: center;

    margin: 0px !important;

}

.react-daterange-picker__calendar-button {
    display: none;
}

.react-daterange-picker__clear-button__icon react-daterange-picker__button__icon {
    display: none;
}

.react-daterange-picker__wrapper {
    background-color: #21222D;
    border: 0px solid rgb(232, 232, 232);

}


.react-daterange-picker__clear-button svg {
    /* display: none; */
    stroke: var(--light-gray) !important;

}

.react-date-picker__inputGroup__divider {
    color: #5F606D;

}

.react-calendar__navigation__label__labelText {
    color: var(--light-gray);
}


.react-calendar__navigation__arrow {
    color: var(--semi-white);
    font-size: 28px;
}

.react-calendar {
    background-color: var(--dark-gray) !important;
    color: white;

    border: 1px solid var(--bg) !important;

    align-items: center;
    /* border-color: hsl(0, 0%, 80%); */
    border-radius: 6px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;

    border: none;
    color: #5F606D;

    margin-right: 5px;
}

.react-daterange-picker__inputGroup__input:invalid {
    background-color: #21222D;
    color: white;
}

.react-calendar__month-view__days__day--weekend {
    color: var(--light-gray);
    text-decoration: none !important;
}

.react-calendar__month-view__weekdays abbr {
    text-decoration: none;
}

.react-calendar__month-view__weekdays__weekday abbr[title] {
    color: var(--light-gray);
    text-decoration: none !important;

}

.react-calendar__month-view__days__day--neighboringMonth {
    color: var(--light-gray);

}

.react-calendar__month-view__days__day {
    color: var(--semi-white);

}

.react-calendar__tile--active {
    background-color: var(--accent);
    color: var(--white)
}

.react-calendar__tile:hover {
    background-color: var(--bg) !important;
    color: var(--white)
}

.react-calendar__tile--now {
    background-color: var(--bg) !important;

}


.react-calendar__tile--hover {
    background-color: var(--accent) !important;
    color: var(--white)
}