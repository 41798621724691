.customLink {
    background-color: white;
    color:grey;
    font-size:0.8rem;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 6px;
    -ms-box-shadow: 0 1px 1px rgba(0,0,0,.16);
    -o-box-shadow: 0 1px 1px rgba(0,0,0,.16);
    box-shadow: 0 1px 1px rgba(0,0,0,.16);
    text-decoration: none;
    outline: none;
    margin-left:0.3rem;
    margin-right:0.3rem;
    border:1px solid lightgrey;
}