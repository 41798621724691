.dropdown {
    width: 100%;

}

.dropdown svg {
    stroke-width: 2px;
    stroke: white;
    fill: transparent;
    width: 20px;
    height: 20px;

}

.darkDropdown {
    /* min-width: 200px; */
    width: 100%;

}

.newTagDropdown {
    background-color: red !important;
}