.editableInput {
    min-width: 70px;

}

.editablePreview {
    min-width: 70px;
    overflow: hidden;
    white-space: nowrap;
    /* Don't forget this one */
    text-overflow: ellipsis;
    padding-left: -4px;


    max-width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    color: var(--light-gray);
    background-color: var(--dark-gray);
    border-radius: 6px;
    border: transparent;
    padding: 6px;

    /* z-index: 7; */
}

.editableDropDown {
    width: 100%;
}

.editableDropDown input {
    color: var(--white) !important
}

.newTableDropDown {
    background-color: transparent !important;

    justify-self: flex-start !important;
    padding: 0 !important;
    margin: 0 !important;
    align-self: baseline !important;
    text-decoration: underline !important;

    width: 100%;


    height: 39px !important;
    overflow: hidden !important;
}

.newTableDropDown div {
    background-color: transparent !important;

}

.newTableDropDown div div {
    background-color: transparent !important;

    padding: 0px;
    text-align: center;
    max-width: 140px;
    color: var(--white) !important;

}

.newTableDropDownNoValue div div {
    text-decoration: underline !important;
    color: var(--accent) !important;

}

.newTagDropdown {
    background-color: transparent !important;
    justify-self: flex-start !important;
    padding: 0 !important;
    margin: 0 !important;
    align-self: baseline !important;
    height: 39px !important;
    overflow: hidden !important;
}

.tradeSourceDropdown {
    height: 100% !important;
    width: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 !important;
    text-decoration: underline !important;

}

.tradeSourceDropdownNoData {
    text-decoration: underline !important;
    color: var(--accent) !important;
}

div[col-id="tradeSource"] {
    padding: 0 !important;
}

div[col-id="tags"] {
    padding: 0 !important;
}