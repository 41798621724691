#multi-leg-entry-table td {
    padding: 1px;
}

#multi-leg-entry-table .chakra-select__icon-wrapper {
    display: none;
}

#multi-leg-entry-table .chakra-select {
    padding: 1px;
    text-align: center;
}

.react-datepicker-multi-leg {

    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);
    font-size: var(--chakra-fontSizes-xs);
    padding-inline-start: var(--chakra-space-2);
    padding-inline-end: var(--chakra-space-2);
    height: var(--chakra-sizes-6);
    border-radius: var(--chakra-radii-sm);
    border: 1px solid;
    background: inherit;
    border-color: var(--chakra-colors-gray-300);



}


#multi-leg-entry-table {

    margin-top: 20px;
    margin-bottom: 20px;
}

.customDeleteButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    width: 35px !important;
    height: 35px !important;
}

.customDeleteButton span {
    fill: var(--red);
    padding: 0;
    margin: 0;
}