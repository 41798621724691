.customTableHeader {
    height: 50px;
}

#droppable {
    /* background: #e2e8f0 !important; */

}

tr {
    /* background: white; */
    /* border-top: 2px solid transparent; */
    /* border-bottom: 2px solid transparent; */

    transition: all .2s ease-in-out;

}


.lightRow:hover {
    background-color: #E2E8F0;
    /* border-top: 2px solid grey; */
    /* border-bottom: 2px solid grey; */
}

.darkRow:hover {
    background-color: #171923;
    /* border-top: 2px solid grey; */
    /* border-bottom: 2px solid grey; */
}



.whiteBackground {
    /* background: white !important; */
    /* border-right:solid lightgrey 1px; */
}



.customButton {
    text-align: center;
    -webkit-appearance: none;
    /* turn off drop-down arrow*/
    -moz-appearance: none;
    border: solid 1px grey;
    font-size: 1rem;

    /* direction: rtl; */
    text-align: center;
    -webkit-appearance: none;
    /* turn off drop-down arrow*/
    -moz-appearance: none;
    border: solid 1px grey;
    border-color: hsl(0, 0%, 58%);

    font-size: 1rem;
    padding-left: 25px;
    /* direction: rtl; */
}

.react-daterange-picker__wrapper {
    border-radius: 4px;
    margin-left: 5px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 58%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
}

.react-daterange-picker__calendar-button {
    display: none;
}

.react-daterange-picker__clear-button__icon react-daterange-picker__button__icon {
    color: black;
}

.selectPicker {
    margin-left: 5px;
}

.selectPicker div {
    border-color: hsl(0, 0%, 58%);

}

.react-date-picker__inputGroup__divider {
    color: lightgrey;
}

.react-daterange-picker__range-divider {
    color: grey;
}

.react-resizable-handle::after {
    height: 10px !important;
    width: 10px !important;
    border-bottom-right-radius: 5px;
}

.react-resizable-handle {
    height: 10px !important;
    width: 10px !important;
    border-bottom-right-radius: 5px;
    background-image: none;
}


.customDateRangePickerDark .react-daterange-picker__wrapper {
    background-color: #313640;
    color: white;
    border: 0px solid rgb(232, 232, 232);
}

.customDateRangePickerLight .react-daterange-picker__wrapper {
    background-color: white;
    color: #313640;
    border: 2px solid rgb(232, 232, 232);
}

.customDateRangePickerLight .react-daterange-picker__clear-button {
    color: white;
}

.customDateRangePickerDark .react-daterange-picker__clear-button {
    filter: invert(100%);

}

.react-calendar {
    color: black;
}







.darkStickyLeft {
    background-color: #2D3748;

}

.lightStickyLeft {

    background-color: white;
}

.ioCircleIcon {
    fill: var(--white) !important;
}

.ioCircleIconAccent {
    stroke: var(--accent) !important;
}

.uploadAddTradeSearchRow:hover {
    background-color: var(--gray);
    cursor: pointer;
}

.drop-zone {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.tableHeaderDrag {}

.stickyTable {
    overflow: scroll;
    position: sticky;
}

.layerZero {
    /* flex-wrap: wrap; */
}

.ag-pivot-mode-panel {
    display: none !important;
}

.ag-last-column-drop {
    display: none !important;
}

.ag-root-wrapper {
    background-color: transparent !important;

    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;

}


.ag-column-drop-horizontal,
.ag-side-buttons {
    background-color: var(--dark-gray) !important;

}

.ag-header {
    background-color: var(--dark-gray) !important;

}

.ag-row {
    background-color: var(--bg) !important;
}

.ag-row-odd {
    background-color: var(--dark-gray) !important;
}

.ag-tool-panel-wrapper {
    background-color: var(--dark-gray) !important;
}


.ag-radio-button-input-wrapper {
    font-family: var(--ag-icon-font-family) !important;
}

.ag-popup-child {
    z-index: 1000 !important;
}