.highlightLink {

    cursor: pointer;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 2px;
    padding-top: 4px;
    padding-bottom: 4px;
    min-width: 100% !important;
    font-size: small;
}

.highlightLink:hover {
    background-color: var(--accent);
}

.highlightLinkTextOnly {
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 2px;
    padding-top: 4px;
    padding-bottom: 4px;
    min-width: 100% !important;
    font-size: 10px;
}