
.modal {
    outline: none;
    position:absolute;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    margin-top:2rem;
    width:300px;
    text-align: center;
    background-color: white;
    padding:1rem 1rem 2rem 1rem;
    box-shadow: 0 0 0 1px rgba(14,41,57,.12), 0 2px 5px rgba(14,41,57,.44), inset 0 -1px 2px rgba(14,41,57,.15);

}