.plusOne {
    position: absolute;
    top: 0px;
    left: 0px;
    transform: translateX(-330%) translateY(20%) !important;

}

.plusTwo {
    position: absolute;
    top: 0px;
    left: 0px;
    transform: translateX(-330%) translateY(280%) !important;

}

.plusThree {
    position: absolute;
    top: 0px;
    left: 0px;
    transform: translateX(250%) translateY(60%) !important;

}

.plusFour {
    position: absolute;
    top: 0px;
    left: 0px;
    transform: translateX(200%) translateY(200%) !important;

}

.graphicHolder {
    width: 0;
    height: 0;
    position: relative;
    top: 50%;
    left: 50%;
}


@media only screen and (max-width: 1200px) {
    .graphicHolder {
        display: none;
    }
}