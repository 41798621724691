.pricingCardBox {
    min-height: 228px;
    background: var(--bg);
    border-radius: 12px;

    min-width: 370px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


    margin-top: 20px;
    margin-bottom: 20px;
}


.priceBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.currency {
    font-size: 18px;
    font-weight: 700;
    padding-top: 12px;
    padding-right: 5px;
    text-align: center;

}

.price {

    font-weight: 700;
    font-size: 34px;
    line-height: 140%;

    color: var(--white);
    text-align: center;


}

.frequencyBox {

    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    display: flex;
    align-items: center;
    text-align: center;

    /* dark/semi-white */

    color: var(--semi-white);
}

.featuresText {

    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* or 25px */

    display: flex;
    align-items: center;
    text-align: center;


    /* dark/semi-white */

    color: var(--semi-white);

    text-decoration: underline;
}

.highlight {
    background: var(--accent);

    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    display: flex;
    align-items: center;
    margin: auto;
    justify-content: center;
    text-align: center;
    /* dark/white */

    height: 43px;
    color: var(--white);


    border-top-left-radius: 12px;
    border-top-right-radius: 12px;


}

.pricingCardBoxWithHighlight {
    min-width: 370px;
    min-height: 228px;
    background: var(--bg);
    border-radius: 12px;
    margin-inline-start: 0px !important;

    margin-top: 20px;
    margin-bottom: 20px;


}

.center {
    justify-content: center;

    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.priceBoxWithHighlight {
    min-width: 370px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    margin-top: 8%;
}