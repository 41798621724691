
.container {
    max-width:90vw;
    margin:1rem 1rem 1rem 1rem;
    border-radius: 5px;
    padding:1rem 2rem 2rem 2rem;
    justify-content: center;
    align-items: left;
    text-align: left;

    background-color:white;
    /* box-shadow: 0 0 0 1px rgba(14,41,57,.12), 0 2px 5px rgba(14,41,57,.44), inset 0 -1px 2px rgba(14,41,57,.15); */
    border:1px solid lightgrey;
    border-radius: 4px;
}