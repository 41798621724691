:root {
  --black: #13151b;
  --bg: #171821;
  --dark-gray: #21222d;
  --gray: #43444d;
  --light-gray: #b5b7c2;
  --semi-white: #9293a0;
  --white: #ffffff;
  --accent: #2595fd;
  --accent-dark: #0d66ba;

  --green: #26c363;
  --red: #ea5848;

  --chart-orange: #feb95a;
  --chart-baby-blue: #a9dfd8b8;
  --chart-blue: #2595fd;
  --chart-dark-blue: #235c95;
  --chart-swamp: rgb(169, 223, 216, 0.5);

  --trade-closed: rgba(234, 88, 72, 0.4);
  --trade-open: rgba(38, 195, 99, 0.4);
  --trade-expired: rgba(254, 185, 90, 0.4);
  --trade-assigned: rgba(169, 223, 216, 0.4);

  --trade-stock: rgba(254, 185, 90, 0.4);
  --trade-call: rgba(169, 223, 216, 0.4);
  --trade-put: rgba(37, 149, 253, 0.4);

  --calendarProfit: rgba(38, 195, 99, 0.1);
  --calendarLoss: rgba(234, 88, 72, 0.1);

  --graph-orange: rgba(254, 185, 90, 0.2);
  --graph-orange-bright: rgba(254, 185, 90, 1);
}

[data-theme="light"] {
  --black: #eceae4;
  --bg: #e8e7de;
  --dark-gray: #deddd2;
  --gray: #bcbbb2;
  --light-gray: #4a483d;
  --semi-white: #6d6c5f;
  --white: #000000;
  --accent: #2595fd;
  --green: #126d35;
  --red: #ce2d1b;
  --chart-orange: #feb95a;
  --chart-baby-blue: #a9dfd8;
  --chart-blue: #2595fd;
  --chart-dark-blue: #235c95;
  --chart-swamp: rgb(169, 223, 216, 0.5);

  --trade-closed: rgba(229, 46, 26, 0.4);
  --trade-open: rgba(38, 195, 99, 0.4);

  --trade-stock: rgba(254, 185, 90, 0.4);
  --trade-call: rgba(169, 223, 216, 0.4);
  --trade-put: rgba(37, 149, 253, 0.4);

  --calendarProfit: rgba(38, 195, 98, 0.661);
  --calendarLoss: rgba(234, 88, 72, 0.595);
}

.black {
  background-color: var(--black);
  width: 40px;
  height: 40px;
  border-radius: 32px;
  margin-right: 10px;
  border: 1.5px solid var(--white);
}

.bg {
  background-color: var(--bg);
  width: 40px;
  height: 40px;
  border-radius: 32px;
  margin-right: 10px;
  border: 1.5px solid var(--white);
}

.darkGray {
  background-color: var(--dark-gray);
  width: 40px;
  height: 40px;
  border-radius: 32px;
  margin-right: 10px;
  border: 1.5px solid var(--white);
}

.gray {
  background-color: var(--gray);
  width: 40px;
  height: 40px;
  border-radius: 32px;
  margin-right: 10px;
  border: 1.5px solid var(--white);
}

.lightGray {
  background-color: var(--light-gray);
  width: 40px;
  height: 40px;
  border-radius: 32px;
  margin-right: 10px;
  border: 1.5px solid var(--white);
}

.semiWhite {
  background-color: var(--semi-white);
  width: 40px;
  height: 40px;
  border-radius: 32px;
  margin-right: 10px;
  border: 1.5px solid var(--white);
}

.white {
  background-color: var(--white);
  width: 40px;
  height: 40px;
  border-radius: 32px;
  margin-right: 10px;
  border: 1.5px solid var(--white);
}

.accent {
  background-color: var(--accent);
  width: 40px;
  height: 40px;
  border-radius: 32px;
  margin-right: 10px;
  border: 1.5px solid var(--white);
}

.green {
  background-color: var(--green);
  width: 40px;
  height: 40px;
  border-radius: 32px;
  margin-right: 10px;
  border: 1.5px solid var(--white);
}

.red {
  background-color: var(--red);
  width: 40px;
  height: 40px;
  border-radius: 32px;
  margin-right: 10px;
  border: 1.5px solid var(--white);
}

/* Panels */

.background {
  background-color: var(--bg);
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.blockOverlayActive {
  background-color: var(--black);
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
}

/* Buttons */

.customSwitch {
}

.customSwitch span {
  background-color: var(--bg) !important;
}

.customSwitch span span {
  background-color: var(--light-gray) !important;
}

.customSwitchActive {
}

.customSwitchActive span {
  background-color: var(--accent) !important;
}

.customSwitchActive span span {
  background-color: var(--white) !important;
}

.customSwitch[data-checked] span {
  background-color: var(--accent) !important;
}

.customSwitch[data-checked] span span {
  background-color: var(--white) !important;
}

.button {
  height: 40px;

  background: var(--dark-gray);
  border-radius: 6px;

  /* max-width: 120px; */
}

.primaryButton {
  background: var(--accent) !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: center !important;

  text-shadow: var(--bg) 0px 0px 10px;
}

.secondaryButton {
  color: var(--accent);
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: center !important;

  text-shadow: var(--bg) 0px 0px 10px;
}

.secondaryButton:hover {
  background-color: var(--gray);
}

.negativeButton {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--red);

  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
}

.outlineButton {
  font-size: 16px !important;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--light-gray);
  background-color: transparent !important;
}

.outlineButtonActive {
  font-size: 16px !important;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--white);
  background-color: transparent !important;
  border-bottom: 2px var(--accent) solid;
  border-radius: 0px !important;
}

.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  padding: 10px 12px;

  flex: none;
  order: 0;
  flex-grow: 1;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: var(--accent);
  background: var(--dark-gray);
  border-radius: 6px;
  stroke-width: 1.5px;
}

.iconButton svg {
  fill: var(--light-gray);
}

.overRideIconColor {
  background-color: var(--bg) !important;
}

.overRideIconColor span {
  padding: 0;
  margin-inline-start: 0;
}

.overRideIconColor svg {
  stroke: transparent !important;
}

.overRideIconColorActive {
  background-color: var(--bg) !important;
}

.overRideIconColorActive span {
  padding: 0;
  margin-inline-start: 0;
}

.overRideIconColorActive svg {
  stroke: transparent !important;
}

.overRideIconColorActive svg path {
  fill: var(--accent) !important;
}

.roundButton {
  width: 40px;
  height: 40px;
  border-radius: 32px;
  border: 2px solid var(--dark-gray);
}

.roundButton:hover {
  background-color: var(--accent) !important;
}

.roundButton:hover svg {
  color: var(--white) !important;
}

.activeRoundIcon svg {
  fill: var(--accent) !important;
}

.activated {
  color: var(--accent);
  stroke: var(--white);
  /* background-color: red; */
}

.activated svg {
  color: transparent;
  stroke: var(--accent);
  fill: transparent;

  width: 20px;
  height: 20px;
}

.deactivated {
  color: var(--white);
  stroke: var(--white);

  fill: transparent;
}

.deactivated svg {
  color: transparent;
  stroke: var(--white);
  width: 20px;
  height: 20px;
}

.bellActivated svg {
  color: transparent;
  stroke: var(--white);
  fill: var(--white);
  width: 20px;
  height: 20px;
}

.bellDeactivated svg {
  color: var(--white);
  stroke: var(--white);
  fill: var(--white);
  width: 20px;
  height: 20px;
}

.roundIcon {
  border-radius: 32px !important;
  background-color: var(--dark-gray) !important;
}

.roundIcon svg {
  stroke: transparent;
  fill: var(--light-gray);
  width: 20px;
  height: 20px;
}

/* Typography */

.link {
}

.link:hover {
  cursor: pointer;
}

.title {
  /* bold/20px */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  /* identical to box height, or 28px */

  display: flex;
  align-items: center;

  /* dark/white */

  color: var(--white);
}

.innerText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: var(--semi-white);

  text-align: left;
  align-self: flex-start;
}

.itemHeading {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: var(--white);

  text-align: left;
  align-self: flex-start;
}

.notes {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: var(--semi-white);
}

/* Input */

.dropdown {
  width: 100%;
}

.dropdown svg {
  stroke-width: 2px;
  stroke: white;
  fill: transparent;
  width: 20px;
  height: 20px;
}

.darkDropdown {
  /* min-width: 200px; */
  width: 100%;
}

.dropdownCreatable {
  min-width: 120px !important;
  width: 120px !important;
}

.inputGroup {
  /* padding-bottom: 15px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start !important;
}

.checkbox {
  font-size: 14px !important;
  border: transparent 0;
}

.textInputFrame {
  max-width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  color: var(--light-gray);
  background-color: var(--dark-gray);
  border-radius: 6px;
  border: transparent;

  z-index: 7;
}

.textInput {
  color: var(--white);
  margin: 0 !important;
}

.textInputLeftIcon {
  stroke: var(--semi-white);
  fill: transparent;
  stroke-width: 2px;
}

.textInputLeftIcon svg {
  width: 20px;
  height: 20px;
}

.textInputLeftIconAdditional {
  stroke: var(--semi-white);
  fill: transparent;
  stroke-width: 2px;
  transform: translateX(50px);
  color: White;
}

.showInputElement {
}

.showButton {
  padding: 0 !important;

  background: transparent !important;
  color: var(--white);
  font-size: 14px !important;
}

.showButton:hover {
  background: transparent !important;
}

/* Structure */

.tabs {
}

.tabPanels {
}

.tab {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;

  color: var(--white);

  border-bottom: transparent;
}

.tabList {
  border-bottom: transparent !important;
  /* overflow-y: hidden; */
  overflow-x: scroll;
  padding: 3px;
}

.tab[aria-selected="true"],
.tab[data-selected] {
  color: var(--accent) !important;
  border-color: var(--accent) !important;
}

/* Widgets */

.widgetPanel {
  /* width: 258px; */
  /* height: 145px; */
  height: 100%;
  width: 100%;

  /* dark/dark-gray */

  background: var(--dark-gray);
  border-radius: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.widgetPanelNoBackground {
  /* width: 258px; */
  /* height: 145px; */
  height: 100%;
  width: 100%;

  /* dark/dark-gray */

  border-radius: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

  border: dashed var(--gray) 2px;
}

.socialSharePanel {
  /* width: 258px; */
  /* height: 145px; */
  height: 100%;
  width: 100%;

  /* dark/dark-gray */

  background: var(--dark-gray);
  border-radius: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  border-color: var(--accent);
  border-width: 2px;
}

.widgetPanelWithOverFlow {
  height: 100%;
  width: 100%;
  background: var(--dark-gray);
  border-radius: 12px;
  padding-left: 20px;
  padding-right: 20px;

  overflow-y: scroll;
}

.widgetHeader {
  height: 36px;

  display: flex;
  justify-content: space-between;

  padding-top: 20px;
}

.widgetButtonContainer {
  margin-top: 20px;

  max-width: 50px;
  display: flex;
  justify-content: space-around;

  flex: 2;
}

.widgetTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  line-height: 140%;
  display: flex;
  align-items: center;
  color: var(--semi-white);

  flex: 6;
}

.infoIcon {
  padding: 0;
  margin: 0;

  width: 24px;
  height: 24px;

  fill: var(--gray);

  stroke: transparent;
  stroke-width: 1.5px;
}

.dragHandleIcon {
  width: 20px !important;
  height: 20px !important;
  fill: var(--semi-white);
  position: relative;
  top: 0px;
  stroke: var(--light-gray);
  stroke-width: 2.5px;
  fill: var(--light-gray);

  z-index: 30;
}

.statNumber {
  padding-top: 12px;
  /* bold/32px */

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  /* font-size: 100%; */
  line-height: 140%;
  /* identical to box height, or 45px */

  display: flex;
  align-items: center;

  /* dark/white */

  color: var(--white);
}

.statChangePositive {
  /* medium/14px */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: center;

  /* status/green */

  color: var(--green);
}

.statChangePositiveLarge {
  /* medium/14px */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: center;

  /* status/green */

  color: var(--green);
}

.statChangeNeutral {
  /* medium/14px */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: center;

  /* status/green */

  color: var(--semi-white);
}

.negativeIcon {
  fill: var(--red);
}

.positiveIcon {
  fill: var(--green);
}

.statChangeNegative {
  /* medium/14px */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: center;

  /* status/green */

  color: var(--red);
}

.statChangeNegativeLarge {
  /* medium/14px */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: center;

  /* status/green */

  color: var(--red);
}

/* Sidebar */

.sideBarIcon {
  width: 43px;
  height: 43px;
  background: var(--dark-gray) !important;
  box-shadow: 8.05051px 24.1515px 89.4501px -11.6285px rgba(22, 52, 80, 0.1);
  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  transition: 0.4s all;
}

.sideBarIcon:hover svg {
  stroke: var(--accent) !important;
  fill: var(--accent) !important;
}

.sideBarIconShort {
  width: 43px;
  height: 43px;
  background: var(--dark-gray) !important;
  box-shadow: 8.05051px 24.1515px 89.4501px -11.6285px rgba(22, 52, 80, 0.1);
  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.sideBarText {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;

  padding-left: 12px;
  /* or 22px */

  display: flex;
  align-items: center;

  /* dark/white */

  color: var(--white);

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 12px;

  min-width: 200px;
}

.sideBarSubtitle {
  /* title */

  position: static;
  width: 63px;
  height: 20px;
  left: 0px;
  top: 24px;

  /* regular/14px */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: center;

  /* dark/semi-white */

  color: var(--semi-white);

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 4px 0px;
}

.sideBarTextInactive {
  min-width: 200px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;

  padding-left: 12px;
  /* or 22px */

  display: flex;
  align-items: center;

  /* dark/white */

  color: var(--light-gray);

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 12px;
}

.plusIcon {
  min-width: 44px !important;
  min-height: 44px !important;
  background: var(--dark-gray) !important;
  width: 44px !important;
  height: 44px !important;
  border-radius: 32px !important;
  color: var(--white) !important;
  font-size: 24px !important;
}

.plusIcon svg {
  stroke: var(--semi-white);
  fill: var(--semi-white);
  stroke-width: 1.5px;
}

.sideBarRow {
  padding-left: 16px;
  border-left: 4px solid transparent;
  cursor: pointer;

  padding-top: 10px;
  padding-bottom: 10px;

  margin-top: 0px !important;
}

.sideBarMenuItemRow {
  padding-left: 16px;
  border-left: 4px solid transparent;
  cursor: pointer;

  padding-top: 6px;
  padding-bottom: 6px;
}

.sideBarMenuItemRowShort {
  padding-left: 16px;
  cursor: pointer;

  padding-top: 6px;
  padding-bottom: 6px;
}

.sideBarMenuItemRowActive {
  padding-left: 16px;
  border-left: 4px solid transparent;
  cursor: pointer;

  padding-top: 6px;
  padding-bottom: 6px;

  border-left: 4px solid var(--accent) !important;
  border-radius: 0px 2px 2px 0px !important;
  border-top-left-radius: 2px !important;
}

.sideBarRowActive {
  border-left: 4px solid var(--accent);
  border-radius: 0px 2px 2px 0px;
  border-top-left-radius: 2px;
  padding-top: 12px;
  padding-bottom: 12px;

  margin-top: 0px !important;
}

.sideBarRowTextContainer {
  text-align: left;
}

.sideBarProfileIcon {
  width: 44px !important;
  height: 44px !important;
  min-width: 44px !important;
  min-height: 44px !important;
  background: var(--accent) !important;
  border-radius: 32px !important;
  color: var(--white) !important;
  font-size: 24px !important;
}

.sideBarProfileIconInactive {
  transition: 2.4s all;

  border: solid 2px transparent;

  min-width: 44px !important;
  min-height: 44px !important;
  width: 44px !important;
  height: 44px !important;
  background: var(--dark-gray) !important;
  border-radius: 32px !important;
  color: var(--semi-white) !important;
  font-size: 24px !important;
}

.sideBarProfileIconInactive:hover {
  border: solid 2px var(--accent);
  background-color: var(--accent) !important;
  color: white !important;
  opacity: 0.5;
}

.plusIcon:hover {
  border: solid 2px var(--accent);
  color: white !important;
}

/* Tables */

.tablesFrame {
  /* margin-top: 20px; */
}

.tablesHeader {
}

.tablesRow {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 140% !important;
  /* identical to box height, or 20px */

  /* dark/white */

  color: var(--white) !important;
}

.tablesRow:hover {
  background-color: var(--dark-gray);
  cursor: pointer;
}

.tablesHead {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 140% !important;

  color: var(--light-gray) !important;
  border-color: transparent !important;

  padding-left: 0 !important;
  margin-left: 0 !important;
}

.customTbody {
}

.customTd {
  border-color: transparent !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 0 !important;
}

/* Modal */

.modal {
  /* background-color: var(--bg) !important; */
}

.customDrawer {
}

.customDrawerContent {
  background-color: var(--dark-gray) !important;
}

.closeButton {
  stroke: var(--dark-gray);
}

.modalContent {
  background-color: var(--bg) !important;

  margin: 0;
}

.basicModal {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

.Glass {
  overflow: hidden !important;
  margin: 0 !important;
  padding: 5px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--white) !important;
  text-align: center !important;
}

.Glass:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;

  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 4px !important;
  transform: 2s all !important;
}

.GlassTitle {
  letter-spacing: 0.01em;

  /* dark/white */

  color: var(--white);

  opacity: 0.8;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  font-size: 10px;
}

.GlassText {
  color: var(--white);

  text-align: left;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  font-size: 14px;
}

.CustomToolTip {
  overflow: hidden !important;
  margin: 0 !important;
  padding: 5px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--white) !important;
  text-align: center !important;
  background-color: transparent !important;
}

.CustomToolTip:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;

  color: var(--white) !important;

  background: rgba(157, 157, 157, 0.6);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(12px);
  border-radius: 4px;
  transform: rotate(-180deg);
  transform: 2s all;
}

.CustomToolTipLeftAligned {
  margin: 0 !important;
  padding: 5px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  color: var(--white) !important;
  background-color: transparent !important;
  text-align: left !important;
}

.CustomToolTipLeftAligned:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(12px);
  border-radius: 4px;
  transform: rotate(-180deg);
  transform: 2s all;
}

/* Charts */

.chartContainer {
  height: 100%;
  width: 100%;
}

/* Extra */

.tag {
  padding: 8px 16px !important;

  /* dark/bg */

  background: var(--bg) !important;
  backdrop-filter: blur(8px) !important;
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 40px !important;
}

.dark {
  background-color: var(--bg) !important;
  padding: 12px 20px !important;
  font-size: 16px !important;
}

.fat {
  padding: 12px 20px !important;
  border-radius: 6px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: white;
  font-size: 16px !important;
}

.customSecondary {
  padding: 12px 20px !important;
  border-radius: 6px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: white;
  background-color: var(--bg) !important;

  font-size: 16px !important;
}

.textInputDark {
  background-color: var(--bg) !important;
  margin: 0 !important;
}

.textArea {
  background-color: var(--bg) !important;
}

.darkGrayBg {
  background-color: var(--dark-gray) !important;
}

.tradeRowHeaderText {
  color: var(--light-gray);
  font-weight: 400;
  font-size: 12px;
}

.insetTabsTall {
}

.insetTabs {
}

.insetTab {
  /* dark/dark-gray */

  border-radius: 6px;

  flex: none;
  align-self: stretch;
  flex-grow: 1;
  margin: 0px 1px;
  border-color: transparent;
  color: var(--semi-white);
  border: 0 !important;
  background: var(--dark-gray);
}

.insetTab[data-selected],
.insetTab[aria-selected="true"] {
  color: var(--white) !important;
  border: 0 !important;
  background: var(--bg);

  border: 1px solid var(--gray) !important;
}

.insetTabPanels {
}

.insetTabList {
  background: var(--dark-gray);
  padding: 4px;
  padding-bottom: 6px;
  border-radius: 8px;
  border: 0 !important;
  width: 100%;
}

.firstBreadcrumb {
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  color: var(--light-gray) !important;
}

.breadcrumb {
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  color: var(--white) !important;
}

.redBackgroundSVG {
  background-color: var(--red) !important;
}

.redBackgroundSVG svg {
  fill: var(--white) !important;
}

::placeholder {
  color: var(--semi-white) !important;
}

.closeIcon {
  cursor: pointer;
}
