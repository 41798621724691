.customLink {
    background-color: dodgerblue;
    color:white;
    font-size:1rem;
    cursor: pointer;

    box-sizing: border-box;
    text-align: center;
    padding: 10px 40px 10px 40px;
    border-radius: 6px;
    -ms-box-shadow: 0 1px 1px rgba(0,0,0,.16);
    -o-box-shadow: 0 1px 1px rgba(0,0,0,.16);
    box-shadow: 0 1px 1px rgba(0,0,0,.16);

    text-decoration: none;

    margin-top:1rem;
    outline: none;


    background-color: black;
    color:white;
    font-size:1rem;
    text-align: center;
    margin-top:1.5rem;
    cursor: pointer;
    font-weight:700;

    box-sizing: border-box;
    text-align: center;
    height: 44px;
    border-radius: 4px;
    border:0px solid;
}