.drag-handle {
    /* position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0;
    text-align: center;
    width:100px; */
    cursor: grab;
}

.dark-handle {
    color: #718096;
}

.light-handle {
    color: #A0AEC0;

}

.right-handle {
    margin-right: 0;
}