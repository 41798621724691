.lightButton {
    background-color: dodgerblue;
    border-radius: 30px;
    color: white;
    font-weight: 300;
    text-decoration: none;
    outline: none;
    width: 300px;
    padding: 1rem 1rem 1rem 1rem;


}

.dropDownGrouping {

    width: 300px;
    justify-self: center;
    align-self: center;
}

.lightButton:hover {
    background-color: white;
    border-radius: 30px;
    border: 1px solid grey;
    color: dodgerblue;


}

.websiteContainer {
    display: flex;
    flex-direction: column;
    border: 1px solid lightgrey;
    width: 80vw;
    /* padding:5px; */
    /* border-radius: 5px; */
}

.topRow {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
}

.websiteTitle {
    flex: 2;
    font-weight: 400;
    padding: 0;
}


.domUrlCon {
    color: white;
    background-color: dodgerblue;
    text-align: center;
    padding: 0.2rem 1rem 0.2rem 1rem;
    margin-right: 1rem;
    border-radius: 50px;
    border: 1px solid white;

}

.domUrlCon:hover {
    color: dodgerblue;
    border: 1px solid dodgerblue;
    background-color: white;
}

.domUrla {
    text-decoration: none;
    color: white;
}

.domUrla:hover {
    color: dodgerblue;
}

.nameserversContainer {
    /* border: solid black 1px; */
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    margin-top: 1rem;
}

.nameserver {
    flex: 1;
    height: 1.5rem;
    text-align: center;
    border-radius: 20px;
    border: 1px solid lightgrey;
    margin: 0 1rem 0 1rem;
    outline: none;
}

.websiteContainerTitle {
    text-align: left;
}

.dnsRec {
    color: grey;
    padding: 0 1rem 0 1rem;
}


.metricsContainer {
    display: flex;
    flex-direction: row;
    margin: 1rem 0 1rem 0;
}

.metric {
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: row;
    color: grey;
    font-size: 0.9rem;
}

.metricValue {
    text-align: center;
    margin-left: 1rem;
    color: black;
    font-size: 1.2rem;
}

.metricLabel {
    text-align: center;
    font-size: 1.2rem;

}


.modal {
    width: 80vw;
    margin: auto;
    background-color: white;
    border: solid black 1px;
    padding: 0 1rem 1rem 1rem;
    margin-top: 5rem;
}

.settings {
    justify-content: center;
    align-items: center;
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


.deleteButton {
    flex: 1;
    text-align: center;
    text-align: center;
    background-color: red;
    border-radius: 30px;
    color: white;
    font-weight: 300;
    text-decoration: none;
    outline: none;
    width: 300px;
    padding: 1rem 1rem 1rem 1rem;
    border: 0px solid grey;
}

.deleteButton:hover {
    background-color: white;
    border-radius: 30px;
    border: 1px solid grey;
    color: red;


}

.lightButtonSettings {
    flex: 1;
    text-align: center;
    text-align: center;
    background-color: dodgerblue;
    border-radius: 30px;
    color: white;
    font-weight: 300;
    text-decoration: none;
    outline: none;
    width: 300px;
    padding: 1rem 1rem 1rem 1rem;
    border: 0px solid grey;
}

.lightButtonSettings:hover {
    background-color: white;
    border-radius: 30px;
    border: 1px solid grey;
    color: dodgerblue;


}

.welcome {
    text-align: left;
}

.description {
    margin-top: 2rem;
    margin-bottom: 2.5rem;
}

.narrow {
    max-width: 45vw;
}

.padder {
    height: 2rem;
}


.WebsiteBoxContainer {
    width: 90wv;
}

.Nicebox {
    max-width: 90vw;
    min-width: 90vw;
    margin: 1rem 1rem 1rem 1rem;
    border-radius: 5px;
    padding: 6rem 2rem 10rem 2rem;
    justify-content: center;
    align-items: center;
    text-align: center;

    background-color: white;
    box-shadow: 0 0 0 1px rgba(14, 41, 57, .12), 0 2px 5px rgba(14, 41, 57, .44), inset 0 -1px 2px rgba(14, 41, 57, .15);
    border-radius: 4px;
}

.NiceboxWebsiteContainer {
    max-width: 90vw;
    min-width: 90vw;
    margin: 1rem;
    border-radius: 5px;
    padding: 0rem 1rem 1rem 1rem;
    justify-content: center;
    align-items: center;
    text-align: left;

    background-color: white;
    box-shadow: 0 0 0 1px rgba(14, 41, 57, .12), 0 2px 5px rgba(14, 41, 57, .44), inset 0 -1px 2px rgba(14, 41, 57, .15);
    border-radius: 4px;
}

.NoWebsites {
    text-align: center;
    margin-bottom: 2rem;
    color: grey;
    font-weight: 500;
}

.icon {
    font-size: 4rem;
    color: grey;
}

.SmallPlusIcon {
    padding-right: 1rem;
}

.domainSpan {
    color: lightgrey;
    padding-left: 2rem;
}

.date {
    padding-right: 2rem;
    color: lightgrey;

}

.from {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: grey;

}


/**
* ----------------------------------------------
* Accordian styles
* ----------------------------------------------
**/
.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.accordion__item+.accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 8px;
    text-align: left;
    border: none;
}

.accordion__button:hover {
    background-color: #ddd;
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}



.filterMenu {
    display: flex;
    flex-direction: row;
}

.form {
    display: flex;
    flex-direction: column;
    max-width: 200px;
}

.react-datepicker-wrapper {
    width: 100%
}

.react-datepicker {
    background-color: red;
    width: 100%;
    min-width: 0px;
    outline: 0;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    font-size: var(--chakra-fontSizes-md);
    -webkit-padding-start: var(--chakra-space-4);
    padding-inline-start: var(--chakra-space-4);
    -webkit-padding-end: var(--chakra-space-4);
    padding-inline-end: var(--chakra-space-4);
    height: var(--chakra-sizes-10);
    border-radius: var(--chakra-radii-md);
    border: 1px solid;
    border-color: inherit;
    background: inherit;

    font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
    overflow: hidden;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 90px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
    height: 8px;
}

.react-datepicker__navigation--previous {
    border-right-color: #cbd5e0;

    &:hover {
        border-right-color: #a0aec0;
    }
}

.react-datepicker__navigation--next {
    border-left-color: #cbd5e0;

    &:hover {
        border-left-color: #a0aec0;
    }
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    display: block;
}

.react-datepicker__header {
    border-radius: 0;
    background: #f7fafc;
}

.react-datepicker,
.react-datepicker__header,
.react-datepicker__time-container {
    /* border-color: #e2e8f0; */
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    font-size: inherit;
    font-weight: 600;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    margin: 0 1px 0 0;
    height: auto;
    padding: 7px 10px;

    &:hover {
        background: #edf2f7;
    }
}

.react-datepicker__day:hover {
    /* background: #edf2f7; */
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background: #3182ce;
    font-weight: normal;

    &:hover {
        background: #2a69ac;
    }
}


.tableRow {}

.groupedRowLight {
    /* border-bottom: 2px rgb(185, 185, 185) solid;; */
    font-weight: 600;

    background-color: #cfd9e4;

}



.groupedRowAltLight {
    font-weight: 500;

    background-color: #E2E8F0;

}

.nonGroupedRowLight {
    transition: all 0.1s;
    cursor: grab;


    background-color: #EDF2F7;
}

.nonGroupedRow:hover+.hiddenRow {
    display: block;
}

.groupedRowDark {
    /* border-bottom: 2px #1A202C solid;; */
    font-weight: 600;
    background-color: #2D3748;

}





.nonGroupedRowDark {
    transition: all 0.1s;
    cursor: grab;
    background-color: #4e5d74;
}

.standardRowDark {
    transition: all 0.1s;
    cursor: grab;
}

.standardRowLight {
    transition: all 0.1s;
    cursor: grab;
}

.nonGroupedRow:hover+.hiddenRow {
    display: block;
}


.hiddenRow {
    position: relative;
    top: -30px;
    left: -20px;
    height: 0px;
    overflow: visible;
    display: none;
}

.nonGroupedRow:hover,
.groupedRow:hover,
.groupedRowAlt:hover {
    /* background: rgb(240, 240, 240) !important;  */

}


td[role=cell] {
    padding: 0px !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
    border-color: transparent;
}


.customTopRow {
    background-color: var(--bg);
}

.customRootRow {
    background-color: var(--bg);
}

.customTableRow {
    border-bottom: 1.5px solid var(--gray);
}

.customTableRow:hover {
    background-color: var(--gray) !important;
}



.roundedCorners:nth-child(1) {
    border-top-left-radius: 6px !important;
}

.roundedCorners {
    /* background-color: var(--bg) !important; */
    /* border-bottom: 1px var(--light-gray) solid !important; */
    /* padding-top: 6px !important; */
    /* padding-bottom: 6px !important; */
}


.roundedCorners:nth-last-child(1) {
    border-top-right-radius: 6px !important;


}

.customLastRow:nth-child(1) {
    border-bottom-left-radius: 6px !important;

}

.customLastRow:nth-last-child(1) {
    border-top-right-radius: 6px !important;
}


.customTableRow {
    /* background-color: red !important; */
    /* border: 2px solid transparent; */
    padding: 0px !important;
}

.customTableRow {}




.firstGroupingTd {
    /* background-color: var(--light-gray) !important; */
    border-bottom: 1px solid var(--light-gray) !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;

}

.firstGroupingRow {
    border-left: 4px solid var(--accent);

}

.subGroupedRow {
    border-left: 4px solid orange;

    background-color: var(--bg);
    background: repeating-linear-gradient(45deg, var(--bg), var(--bg) 6px, var(--dark-gray) 9px, var(--dark-gray) 9px);
}

.zeroGroupingRow {
    /* border-left: 4px solid var(--red) !important; */

}

.strategyCell {
    /* background-color: var(--dark-gray) !important;
    background-color: var(--dark-gray) !important;

    position: absolute; */

    min-width: 180px;

}



.customTableRow:hover {
    /* -webkit-box-shadow: inset 0px 0px 0px 1px var(--accent) !important;
    -moz-box-shadow: inset 0px 0px 0px 1px var(--accent) !important;
    box-shadow: inset 0px 0px 0px 1px var(--accent) !important;
    background-color: var(--accent); */
}

.actionsCell {
    width: 40px;

}

.columnChangerOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 2;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tradeTableContainerExtended {
    width: 100%;
    max-height: calc(85vh) !important;
    max-width: calc(100vw - 330px) !important;
    /* min-height: calc(85vh) !important; */

    overflow: scroll;
}

.tradeTableContainer {
    width: 100%;
    max-height: calc(85vh) !important;
    /* min-height: calc(85vh) !important; */
    max-width: calc(100vw - 140px) !important;
    overflow: scroll;
}


@media only screen and (max-width: 800px) {




    .allOpenClosed {
        display: none;
        ;
    }
}

@media only screen and (max-width: 600px) {

    .dropDownGrouping {
        display: none;
    }

    .pageNumber {
        display: none;
    }


}

.stockTicker {
    background-color: var(--dark-gray);
    min-width: 80px;
    height: 100% !important;

    border: 1px solid var(--dark-gray);

}

.stockTicker2 {
    min-width: 80px;
    height: 100% !important;


}


.stockTickerCell {
    /* background-color: var(--dark-gray) !important; */
    margin: 0 !important;
    z-index: 0 !important;
    /* transform: translateX(-2px) scale(1.02); */
    height: 45px;
}

.chevron {
    height: 100% !important;
    /* background-color: var(--dark-gray); */
}

/* minW={'80px'} spacing='12px' bg="var(--dark-gray)" */


.removeCircle {
    margin-right: 16px;
    width: 24px;
    height: 24px;
    fill: var(--white);
    color: var(--white);
}


.addCircle {
    margin-right: 16px;
    width: 24px;
    height: 24px;
    fill: var(--light-gray);
    color: var(--light-gray);
}