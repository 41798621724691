.DARK {
    background-color: #1a202c;

}

.DARK h1 {
    color: white;
}

.DARK ul {
    color: white;
}

.DARK li {
    color: white;
}

.DARK h2 {
    color: white;
}

.DARK h3 {
    color: white;
}

.DARK p {
    color: white;
}

.DARK input {
    color: white;
}

.DARK a {
    color: rgb(15, 171, 255) !important;
}

.LIGHT {
    background-color: white;

}

.LIGHT p {
    color: black;
}

.LIGHT li {
    color: black;
}

.LIGHT h2 {
    color: black;
}

.LIGHT h1 {
    color: black;
}

.LIGHT h3 {
    color: black;
}

.LIGHT a {
    color: rgb(0, 115, 177);
}

.aspectRatioSet {
    object-fit: cover;
    width: 100%;
    height: 480px;
    object-position: 0;
}

.articleArrow svg {

    height: 50px;
    width: 50px;
    vertical-align: middle;

}

.articleArrow2 svg {

    height: 60px;
    width: 60px;
    vertical-align: middle;

    transform: translateY(4px);
}

.articlePages {
    font-size: 20px;
    transform: translateY(-5px);
}

.mde-text,
.react-mde-tabbed-layout {
    background: var(--dark-gray);
    height: 1000px !important;
    border: 0 !important;
}

.mde-header {
    background: var(--dark-gray) !important
}

.svg-icon {
    color: var(--white);
}