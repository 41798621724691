


.waveContainer {
    position: relative;
 
    min-width:200px;
    max-width:200px;
    min-height:200px;
    max-height:200px;
    
    display: flex;
    justify-content: center;
    align-items: center;


    padding: 0;
    margin: 0;

    border: solid 2px var(--gray);

}

.wave {
    position: absolute  ;
    z-index: 2;
    border-radius: 100%;

    width:250px;
    height:200px;
}

.wave svg {
    border-radius: 100%;

}

.wave2 {
    position: absolute  ;
    z-index: 2;

    width:250px;
    height:200px;
}

.wave2 svg {
    border-radius: 100%;



}

.label {
    position: absolute;
    text-align: center;
    z-index: 3;
    color: var(--white);
    text-shadow: 0px 0px 4px var(--bg);
}