.strongBuyValue,
.buyValue,
.holdValue,
.sellValue,
.strongSellValue {
    background-color: var(--bg) !important;
}

.strongBuyValue div {
    background: linear-gradient(270.01deg, var(--accent) 0.01%, rgba(38, 195, 99, 0) 101.01%) !important;

}

.buyValue div {
    background: linear-gradient(270.01deg, #26C363 0.01%, rgba(38, 195, 99, 0) 101.01%) !important;

}


.holdValue div {
    background: linear-gradient(270deg, #A9DFD8 0%, rgba(169, 223, 216, 0) 100%);

}

.sellValue div {
    background: linear-gradient(270deg, #FEB95A 0%, rgba(254, 185, 90, 0) 100%);

}

.strongSellValue div {
    background: linear-gradient(270deg, #EA5848 0%, rgba(234, 88, 72, 0) 100%);
}


.institutionProgress div {
    background: var(--accent);
    border-right: 2px solid var(--white);

}

.insiderProgress div {
    background: var(--chart-baby-blue);
    border-right: 2px solid var(--white);
}

.otherProgress div {
    background: var(--chart-orange);
    border-right: 2px solid var(--white);

}

.fundProgress div {
    background: var(--chart-dark-blue);
    border-right: 2px solid var(--white);
}