.customInput {
    text-align: left;
    margin-top:0.2rem;
    margin-bottom:0.3rem;

    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 12px;
    width: 100%;
    border-radius: 5px;
  
    -webkit-font-smoothing: antialiased;
    border: 1px solid lightgrey;
    border-top-color: lightgrey;
    font-size: 18px;


    text-align: left;
    margin-top:0.2rem;
    margin-bottom:0.3rem;

    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 12px;
    width: 100%;
    border-radius: 8px;
  
    -webkit-font-smoothing: antialiased;
    border: 1px solid white;
    border-top-color: white;
    font-size: 18px;
    background-color: #f2f4f7;

    
}